import emailjs from 'emailjs-com';
import { IDEALAB, CONTACT } from '../constants/constants'

const TEMPLATE_IDS = {
    [IDEALAB]: process.env.VUE_APP_EMAIL_TEMPLATE_ID,
    [CONTACT]: process.env.VUE_APP_EMAIL_CONTACT_TEMPLATE_ID,
}

async function sendEmailService(type, data) {
    try {
        const res = await emailjs.send(
            process.env.VUE_APP_EMAIL_SERVICE_ID,
            TEMPLATE_IDS[type],
            data,
            process.env.VUE_APP_EMAIL_USER_ID
        );

        if (res) {
            return true;
        }
    } catch (error) {
        console.log({ error });
        return false;
    }
}

export { sendEmailService }